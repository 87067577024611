/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { IListingData } from '../../models/listing';
import { paymentTermsHeaders } from './content';
import { useLazyQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { mapToListingPaymentTermsData } from './utils';
import { getPolicyBillsThroughBusinessPartnerQuery } from './queries';
import ListingTableWithPagination from '../../components/form-fields/table/ListingTableWithPagination';

interface IPolicyPaymentTermsSchedule {
  businessPartnerId: string;
}

const BusinessPartnersPaymentTerms: React.FC<IPolicyPaymentTermsSchedule> = ({
  businessPartnerId,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [getPolicyBillsLazy] = useLazyQuery(
    getPolicyBillsThroughBusinessPartnerQuery()
  );

  const loadData = async (
    currentPage: number,
    pageSize = tableData.pageSize
  ) => {
    setLoading(true);

    try {
      const result = await getPolicyBillsLazy({
        variables: {
          businessPartnerId: businessPartnerId,
          pageNumber: currentPage,
          pageSize,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = result.data;

      const newTableData = mapToListingPaymentTermsData(data);
      setTableData({
        ...newTableData,
      });
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = async (
    page: number,
    searchKey?: string,
    rowsPerPage?: number,
    order?: string,
    orderBy?: string
  ) => {
    await loadData(page, rowsPerPage || tableData.pageSize);
  };

  return (
    <>
      <ListingTableWithPagination
        inlineTitle="Payment Terms Schedule"
        name="paymentTermsSchedule"
        data={tableData}
        headers={paymentTermsHeaders}
        onPageChange={handlePageChange}
        loader={loading}
        inline
        orderByAscendingByDefault
        disableSelection
      />
    </>
  );
};

export default BusinessPartnersPaymentTerms;
