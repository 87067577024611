import { gql } from '@apollo/client';

export function getProductionPolicyDetails() {
  return gql`
    query getProductionPolicyDetails($id: String!) {
      Production {
        entities {
          policy(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  PolicyNumber
                  PolicyName
                  ClauseText
                  InternalCode
                  PolicyEffectiveDate
                  PolicyExpiryDate
                  modifiedOn
                  createdOn
                  Status
                  DescriptionOfRisk
                  GeoLocation {
                    Id
                    Code
                    Title
                  }
                  MotorDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          VignetteCode
                          Vignette
                          AgencyRepairYear
                          NameOnLicenseEn
                          NameOnLicenseAr
                          VehicleID {
                            views {
                              Production_all {
                                properties {
                                  PlateCode {
                                    Id
                                    Code
                                    Title
                                  }
                                  PlateNumber
                                  UsageType {
                                    Id
                                    Title
                                  }
                                  BodyType {
                                    Id
                                    Title
                                  }
                                  EngineType {
                                    Id
                                    Title
                                  }
                                  Make {
                                    Id
                                    Title
                                  }
                                  Model {
                                    Id
                                    Title
                                  }
                                  YearOfMake
                                  Engine
                                  Weight
                                  EngineSize
                                  SeatingCapacity
                                  Chassis
                                  HorsePower
                                  CarValue
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  TravelDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Destination {
                            Id
                            Code
                            Title
                          }
                          Duration
                        }
                      }
                    }
                  }
                  MedicalDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Class {
                            Code
                            Title
                          }
                          Level {
                            Code
                            Title
                          }
                          Nssf
                          CoversID {
                            id
                            views {
                              PlanConfigManagement_all {
                                properties {
                                  Name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ExpatDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          Exclusion
                          Remarks
                          MedicalClass {
                            Code
                            Title
                            Id
                          }
                          Continuity
                          ContinuityNumber
                          BarCodeFullCode
                        }
                      }
                    }
                  }
                  PrimaryBPID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          RelatedCompany {
                            Code
                            Title
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  MarineHullDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          VesselName
                          ConnectionPort
                          TerritorialWaters
                          Usage
                          Builder
                          ConstructionYear
                          RegisteryPort
                          Length
                          Weight
                          Height
                          EngineType
                          EngineNumber
                          EngineYearBuilt
                          EngineHorsePower
                          EngineMaxDesignedSpeed
                          EngineFuel
                          Remarks
                        }
                      }
                    }
                  }
                  PropertyDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Order
                          PlanCoverPropertyDetails {
                            SumInsured
                            Premium
                            RiskRate
                            RiskDescription
                            Location
                            PlanCoverID
                            GeoLocation {
                              Id
                              Code
                              Title
                            }
                          }
                        }
                      }
                    }
                  }
                  BBBDetailsID {
                    id
                    views {
                      Production_all {
                        properties {
                          Id
                          NumberOfEmployees
                          UnderwritingYear
                          Infidelity
                          Burglary
                          HoldUp
                          InSafe
                          InTransit
                          ForgedCheckes
                          CounterFeitCurrencies
                          Clause6
                          BranchName
                          IsMain
                          IsAgency
                          Falsification
                        }
                      }
                    }
                  }
                  PlanID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                          ClauseEditable
                          MaxNbInsured
                          AcalCategory {
                            Id
                            Code
                            Title
                          }
                          SOSService
                          ReplacementCarValue
                          ReplacementCarDays
                          ReplacementCarApplied
                          GRLimitDays
                        }
                      }
                    }
                  }
                  BusinessUserID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          AllowPlanCoverEdits
                          AllowClauseEdits
                          EditableLineChargesIDs {
                            id
                            views {
                              PlanConfigManagement_all {
                                properties {
                                  Id
                                }
                              }
                            }
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              production {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
              production {
                pricingOption {
                  pricingOption_PolicyCost
                  pricingOption_ChargesPercentage
                  pricingOption_TPAFeesType
                  pricingOption_TPAFeesAmount
                  pricingOption_TPAFeesPercentage
                  pricingOption_TPAFeesApplicableOn
                  pricingOption_BasicPremium
                  pricingOption_PlanToPolicyRate
                  pricingOption_NetPremium
                  pricingOption_GrossPremium
                  pricingOption_TaxOnCommissionAmount
                  pricingOption_ReinsuranceTaxAmount
                  pricingOption_MunicipalityTaxAmount
                  pricingOption_TotalPremium
                  pricingOption_ChargesAmount
                  pricingOption_TPAFeesTotalAmount
                  pricingOption_FixedStampAmount
                  pricingOption_ProportionalStampAmount
                  pricingOption_Status
                  pricingOption_createdOn
                  pricingOption_SumInsured
                  pricingOption_PricingOptionCurrency {
                    Title
                    Symbol
                    Code
                  }
                }
              }
            }
          }
          policyDocument(id: $id) {
            id
            views {
              Production_download {
                properties {
                  PolicyID {
                    id
                  }
                  Document {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                  Version
                  DocumentName
                  DocumentType
                  UploadedManually
                }
              }
            }
          }
        }
        queries {
          getPolicyPropertyDetails(selectedPolicyID: $id) {
            production_PolicyProperty_PlanCoverID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    PolicyCoverID {
                      id
                      views {
                        PlanConfigManagement_all {
                          properties {
                            Name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            production_PolicyProperty_Order
            production_PolicyProperty_Id
            production_PolicyProperty_PlanCoverPropertyDetails {
              RiskDescription
              Location
              GeoLocation {
                Id
                Code
                Title
              }
              PlanCoverID
              RiskRate
              SumInsured
              Premium
            }
          }
          getPolicyPlanCovers(selectedPolicyID: $id) {
            production_PolicyPlanCover_ProposalID {
              id
              views {
                Production_all {
                  properties {
                    PolicyCurrency {
                      Code
                      Title
                      Symbol
                    }
                  }
                }
              }
            }
            policyCover_Name
            production_PolicyPlanCover_Id
            production_PolicyPlanCover_CoverPremiumPercentage
            production_PolicyPlanCover_CoverPremiumValue
            production_PolicyPlanCover_ExcessOnClaimType
            production_PolicyPlanCover_ExcessOnClaimAmount
            production_PolicyPlanCover_ExcessOnClaimPercentage
            production_PolicyPlanCover_ExcessOnClaimDays
            production_PolicyPlanCover_ClauseID {
              id
            }
            production_PolicyPlanCover_CoverPremium
            production_PolicyPlanCover_TotalExcessOnClaim
            production_PolicyPlanCover_CoverSumInsuredBasic
            production_PolicyPlanCover_CoverPremiumBasic
            production_PolicyPlanCover_CoverSumInsuredArabic
            production_PolicyPlanCover_CoverPremiumArabic
            planCover_Id
            planCover_PolicyCoverID {
              id
            }
            production_PolicyPlanCover_IsMain
            production_PolicyPlanCover_IsEditable
            production_PolicyPlanCover_CoverSumInsured
            production_PolicyPlanCover_SumInsuredIsAdditive
            production_PolicyPlanCover_IsMandatory
            production_PolicyPlanCover_CoverPremiumType
            production_PolicyPlanCover_CoverEffectiveFrom
            production_PolicyPlanCover_CoverEffectiveTo
          }
          getPolicyBankersBlanketBondDetails(selectedPolicyID: $id) {
            production_PolicyBBBond_BranchName
            production_PolicyBBBond_IsMain
            production_PolicyBBBond_IsAgency
            production_PolicyBBBond_NumberOfEmployees
            production_PolicyBBBond_UnderwritingYear
            production_PolicyBBBond_Infidelity
            production_PolicyBBBond_Burglary
            production_PolicyBBBond_HoldUp
            production_PolicyBBBond_InSafe
            production_PolicyBBBond_InTransit
            production_PolicyBBBond_ForgedCheckes
            production_PolicyBBBond_CounterFeitCurrencies
            production_PolicyBBBond_Clause6
            production_PolicyBBBond_Falsification
          }
          getPolicyBusienssPartnerDetails(selectedPolicyID: $id) {
            production_PolicyBusinessPartner_Id
            production_PolicyBusinessPartner_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            production_PolicyBusinessPartner_PlanCommissionType
            production_PolicyBusinessPartner_PlanCommissionRate
            production_PolicyBusinessPartner_CommissionPercentage
            production_PolicyBusinessPartner_PlanCommissionAmount
            production_PolicyBusinessPartner_IsPrimary
            production_PolicyBusinessPartner_ApplicableOn {
              Code
              createdOn
              Status
            }

            production_PolicyBusinessPartner_PolicyCommissionAmount
          }
          GetPolicyPerson(policyID: $id) {
            production_PolicyPerson_Id
            production_PolicyPerson_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    PersonType
                  }
                }
              }
            }
            production_PolicyPerson_Percentage
            production_PolicyPerson_PersonCode
            production_PolicyPerson_FullName
            production_PolicyPerson_Type
            production_PolicyPerson_OccupationClass {
              Id
              Code
              Title
            }
            production_PolicyPerson_Profession
            production_PolicyPerson_DateOfBirth
            production_PolicyPerson_NationalityOne {
              Title
            }
            production_PolicyPerson_PhoneDetails {
              PhoneType
              PhoneNumber
            }
            production_PolicyPerson_PersonAddressID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    IsPrimary
                    Address {
                      Country {
                        Id
                        Title
                      }
                      City
                      Street
                      Building
                    }
                  }
                }
              }
            }
            production_PolicyPerson_Address {
              Building
              Street
              City
              Country {
                Id
                Title
              }
              AddressDetails
            }
            production_PolicyPerson_OrderNumber
            production_PolicyPerson_Relation
            production_PolicyPerson_Gender
            production_PolicyPerson_Smoker
            production_PolicyPerson_PassportNumber
            production_PolicyPerson_GRLimitDays
            production_PolicyPerson_CardNumber
            production_PolicyPerson_Continuity
            production_PolicyPerson_TPAReference1
            production_PolicyPerson_TPAReference2
            production_PolicyPerson_Exclusions
            production_PolicyPerson_Remarks
            production_PolicyPerson_LimitOfCover
          }
          getPolicyMarineDetails(selectedPolicyID: $id) {
            production_PolicyMarine_VesselAge
            production_PolicyMarine_VesselName
            production_PolicyMarine_DepartureFrom
            production_PolicyMarine_DestinationTo
            production_PolicyMarine_TransportationVia
            production_PolicyMarine_Survey
            production_PolicyMarine_CargoContract
            production_PolicyMarine_LCNumber
            production_PolicyMarine_LCIssueDate
            production_PolicyMarine_Matter {
              Code
              Title
            }
            production_PolicyMarine_Description
            production_PolicyMarine_Bank
            production_PolicyMarine_ClaimsPayableBy
            production_PolicyMarine_Loading
            production_PolicyMarine_Remarks
            production_PolicyMarine_SumPerShipment
          }
          getInsuredPricingOptionTravel(selectedPolicyID: $id) {
            production_InsuredPricingOptionTravel_Id
            production_InsuredPricingOptionTravel_PolicyPersonID {
              id
              views {
                Production_all {
                  properties {
                    FullName
                    Relation
                    IsPrincipal
                    ReferenceNumber
                  }
                }
              }
            }
            production_InsuredPricingOptionTravel_Age
            production_InsuredPricingOptionTravel_CoversSumInsured
            production_InsuredPricingOptionTravel_SumInsured
            production_InsuredPricingOptionTravel_SumInsuredBasic
            production_InsuredPricingOptionTravel_NetPremium
            production_InsuredPricingOptionTravel_NetPremiumBasic
            production_InsuredPricingOptionTravel_Charges
            production_InsuredPricingOptionTravel_TPAFees
            production_InsuredPricingOptionTravel_ProportionalStamp
            production_InsuredPricingOptionTravel_MunicipalityTax
            production_InsuredPricingOptionTravel_TotalPremium
            production_InsuredPricingOptionTravel_GrossPremium
            production_InsuredPricingOptionTravel_Cost
            production_InsuredPricingOptionTravel_FixedStamp
            policyPerson_OrderNumber
          }
          getInsuredPricingOptionMedical(selectedPolicyID: $id) {
            production_InsuredPricingOptionMedical_Id
            policyPerson_ReferenceNumber
            policyPerson_PrincipalReference
            policyPerson_IsPrincipal
            production_InsuredPricingOptionMedical_PolicyPersonID {
              id
              views {
                Production_all {
                  properties {
                    FullName
                    Relation
                  }
                }
              }
            }
            production_InsuredPricingOptionMedical_Age
            production_InsuredPricingOptionMedical_CoverSumInsured
            production_InsuredPricingOptionMedical_SumInsuredIsAdditive
            production_InsuredPricingOptionMedical_CoverNetPremiumValue
            production_InsuredPricingOptionMedical_CoverCharges
            production_InsuredPricingOptionMedical_CoverTPAFees
            production_InsuredPricingOptionMedical_CoverProportionalStamp
            production_InsuredPricingOptionMedical_CoverCost
            production_InsuredPricingOptionMedical_CoverMunicipalityTax
            production_InsuredPricingOptionMedical_CoverGrossPremium
            production_InsuredPricingOptionMedical_CoverTotalPremium

            production_InsuredPricingOptionMedical_CoverClass {
              Title
            }
            production_InsuredPricingOptionMedical_CoverLevel {
              Title
            }
            production_InsuredPricingOptionMedical_CoverFixedStamp
            production_InsuredPricingOptionMedical_CoverNssf
            planSpecificMedical_PolicyCoverID {
              views {
                PlanConfigManagement_all {
                  properties {
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getProductionDocuments() {
  return gql`
    query getProductionDocuments($id: String!) {
      Production {
        queries {
          regeneratedDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: false
          ) {
            production_PolicyDocument_Id
            production_PolicyDocument_PolicyID {
              id
            }
            production_PolicyDocument_Document {
              id
              location
              path
              fileName
              length
              contentType
            }
            production_PolicyDocument_Version
            production_PolicyDocument_createdOn
            production_PolicyDocument_DocumentName
            production_PolicyDocument_DocumentType
            production_PolicyDocument_UploadedManually
          }
          additionalDocuments: getNewPolicyDocuments(
            policyId: $id
            uploadedManually: true
          ) {
            production_PolicyDocument_Id
            production_PolicyDocument_PolicyID {
              id
            }
            production_PolicyDocument_Document {
              id
              location
              path
              fileName
              length
              contentType
            }
            production_PolicyDocument_Version
            production_PolicyDocument_createdOn
            production_PolicyDocument_DocumentName
            production_PolicyDocument_DocumentType
            production_PolicyDocument_UploadedManually
          }
        }
      }
    }
  `;
}

export function getPolicyBills() {
  return gql`
    query getPolicyBills(
      $policyId: String!
      $pageNumber: Int!
      $pageSize: Int!
    ) {
      Accounting {
        queries {
          GetPolicyBills(
            PolicyID: $policyId
            pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
          ) {
            paging {
              pageSize
              totalCount
              pageNumber
            }
            items {
              accounting_Bills_Id
              accounting_Bills_BillNumber
              accounting_Bills_DueDate
              accounting_Bills_BillType
              accounting_Bills_TotalPremium
              accounting_Bills_OutstandingBalance
              accounting_Bills_TotalCommission
              payableBills_TaxOnCommission
              payableBills_OutstandingBalance
              payablePayment_AmountReceived
              receivablePayment_AmountReceived
              accounting_Bills_Currency {
                Symbol
              }
            }
          }
        }
      }
    }
  `;
}

export function businessPartnerQuery() {
  return gql`
    query businessPartnerQuery($billID: String!) {
      Accounting {
        queries {
          GetBusinessPartnersCommissions(BillID: $billID) {
            businessPartner_FullName
            policyBusinessPartner_CommissionPercentage
            accounting_Bills_TotalCommission
            policyBusinessPartner_PolicyCommissionAmount
            accounting_Bills_Currency {
              Symbol
            }
          }
        }
      }
    }
  `;
}

export function getPlanPolicySpecificMedicalCovers() {
  return gql`
    query getPlanPolicySpecificMedicalCovers($selectedPlanID: String!) {
      PlanConfigManagement {
        queries {
          getPlanSpecificMedicalCovers(selectedPlanId: $selectedPlanID) {
            planConfigManagement_PlanSpecificMedical_Id
            planConfigManagement_PlanSpecificMedical_PolicyCoverID {
              id
              views {
                PlanConfigManagement_all {
                  properties {
                    ExternalCode
                    Name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getProductionGroupPolicyDetails() {
  return gql`
    query getProductionGroupPolicyDetails($id: String!) {
      Production {
        entities {
          policy(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  PolicyNumber
                  PolicyName
                  ClauseText
                  InternalCode
                  PolicyEffectiveDate
                  PolicyExpiryDate
                  modifiedOn
                  createdOn
                  Status
                  DescriptionOfRisk
                  GeoLocation {
                    Id
                    Code
                    Title
                  }
                  PrimaryBPID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          RelatedCompany {
                            Code
                            Title
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  LineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  SublineID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  PlanID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                          ClauseEditable
                          MaxNbInsured
                          AcalCategory {
                            Id
                            Code
                            Title
                          }
                          SOSService
                          ReplacementCarValue
                          ReplacementCarDays
                          ReplacementCarApplied
                          GRLimitDays
                        }
                      }
                    }
                  }
                  BusinessUserID {
                    views {
                      SalesforceManagement_all {
                        properties {
                          Code
                          AllowPlanCoverEdits
                          AllowClauseEdits
                          EditableLineChargesIDs {
                            id
                            views {
                              PlanConfigManagement_all {
                                properties {
                                  Id
                                }
                              }
                            }
                          }
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              production {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
              production {
                pricingOption {
                  pricingOption_PolicyCost
                  pricingOption_ChargesPercentage
                  pricingOption_TPAFeesType
                  pricingOption_TPAFeesAmount
                  pricingOption_TPAFeesPercentage
                  pricingOption_TPAFeesApplicableOn
                  pricingOption_BasicPremium
                  pricingOption_PlanToPolicyRate
                  pricingOption_NetPremium
                  pricingOption_GrossPremium
                  pricingOption_TaxOnCommissionAmount
                  pricingOption_ReinsuranceTaxAmount
                  pricingOption_MunicipalityTaxAmount
                  pricingOption_TotalPremium
                  pricingOption_ChargesAmount
                  pricingOption_TPAFeesTotalAmount
                  pricingOption_FixedStampAmount
                  pricingOption_ProportionalStampAmount
                  pricingOption_Status
                  pricingOption_createdOn
                  pricingOption_SumInsured
                  pricingOption_PricingOptionCurrency {
                    Title
                    Symbol
                    Code
                  }
                }
              }
            }
          }
          policyDocument(id: $id) {
            id
            views {
              Production_download {
                properties {
                  PolicyID {
                    id
                  }
                  Document {
                    id
                    location
                    path
                    fileName
                    length
                    contentType
                  }
                  Version
                  DocumentName
                  DocumentType
                  UploadedManually
                }
              }
            }
          }
        }
        queries {
          getPolicyPlanCovers(selectedPolicyID: $id) {
            production_PolicyPlanCover_ProposalID {
              id
              views {
                Production_all {
                  properties {
                    PolicyCurrency {
                      Code
                      Title
                      Symbol
                    }
                  }
                }
              }
            }
            policyCover_Name
            production_PolicyPlanCover_Id
            production_PolicyPlanCover_CoverPremiumPercentage
            production_PolicyPlanCover_CoverPremiumValue
            production_PolicyPlanCover_ExcessOnClaimType
            production_PolicyPlanCover_ExcessOnClaimAmount
            production_PolicyPlanCover_ExcessOnClaimPercentage
            production_PolicyPlanCover_ExcessOnClaimDays
            production_PolicyPlanCover_ClauseID {
              id
            }
            production_PolicyPlanCover_CoverPremium
            production_PolicyPlanCover_TotalExcessOnClaim
            production_PolicyPlanCover_CoverSumInsuredBasic
            production_PolicyPlanCover_CoverPremiumBasic
            production_PolicyPlanCover_CoverSumInsuredArabic
            production_PolicyPlanCover_CoverPremiumArabic
            planCover_Id
            planCover_PolicyCoverID {
              id
            }
            production_PolicyPlanCover_IsMain
            production_PolicyPlanCover_IsEditable
            production_PolicyPlanCover_CoverSumInsured
            production_PolicyPlanCover_SumInsuredIsAdditive
            production_PolicyPlanCover_IsMandatory
            production_PolicyPlanCover_CoverPremiumType
            production_PolicyPlanCover_CoverEffectiveFrom
            production_PolicyPlanCover_CoverEffectiveTo
          }
          getPolicyBusienssPartnerDetails(selectedPolicyID: $id) {
            production_PolicyBusinessPartner_Id
            production_PolicyBusinessPartner_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            production_PolicyBusinessPartner_PlanCommissionType
            production_PolicyBusinessPartner_PlanCommissionRate
            production_PolicyBusinessPartner_CommissionPercentage
            production_PolicyBusinessPartner_PlanCommissionAmount
            production_PolicyBusinessPartner_IsPrimary
            production_PolicyBusinessPartner_ApplicableOn {
              Code
              createdOn
              Status
            }

            production_PolicyBusinessPartner_PolicyCommissionAmount
          }
          GetPolicyPerson(policyID: $id) {
            production_PolicyPerson_Id
            production_PolicyPerson_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    PersonType
                  }
                }
              }
            }
            production_PolicyPerson_Percentage
            production_PolicyPerson_PersonCode
            production_PolicyPerson_FullName
            production_PolicyPerson_Type
            production_PolicyPerson_Profession
            production_PolicyPerson_DateOfBirth
            production_PolicyPerson_NationalityOne {
              Title
            }
            production_PolicyPerson_PhoneDetails {
              PhoneType
              PhoneNumber
            }
            production_PolicyPerson_PersonAddressID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    IsPrimary
                    Address {
                      Country {
                        Id
                        Title
                      }
                      City
                      Street
                      Building
                    }
                  }
                }
              }
            }
            production_PolicyPerson_Address {
              Building
              Street
              City
              Country {
                Id
                Title
              }
              AddressDetails
            }
            production_PolicyPerson_OrderNumber
            production_PolicyPerson_Relation
            production_PolicyPerson_Gender
            production_PolicyPerson_Smoker
            production_PolicyPerson_PassportNumber
            production_PolicyPerson_GRLimitDays
            production_PolicyPerson_CardNumber
            production_PolicyPerson_Continuity
            production_PolicyPerson_TPAReference1
            production_PolicyPerson_TPAReference2
            production_PolicyPerson_Exclusions
            production_PolicyPerson_Remarks
            production_PolicyPerson_LimitOfCover
          }
        }
      }
    }
  `;
}

export function getMarineGroupPolicyList() {
  return gql`
    query getMarineGroupPolicyList(
      $currentPage: Int!
      $currentPageSize: Int!
      $id: String!
      $type: Production_PolicyTypes!
    ) {
      Production {
        queries {
          getPolicyList(
            parentPolicyID: $id
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            type: $type
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              production_Policy_Id
              production_Policy_PolicyName
              production_Policy_PolicyNumber
              production_Policy_SublineID {
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_PolicyEffectiveDate
              production_Policy_PolicyExpiryDate
              production_Policy_Status
              production_Policy_StatusReason {
                Code
                Title
              }
              policyMarine_Id
              production_Policy_InsuredID {
                id
                views {
                  Production_all {
                    properties {
                      PersonCode
                      FullName
                    }
                  }
                }
              }
              production_Policy_createdOn
              production_Policy_modifiedOn
              line_Id
              line_Name
              line_ExternalCode
              production_Policy_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_BusinessUserID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                    }
                  }
                }
              }
              production_Policy_PolicyBPID {
                id
              }
              policyMarine_VesselName
              policyMarine_VesselAge
              policyMarine_Matter {
                Title
              }
              policyMarine_DestinationTo
              policyMarine_DepartureFrom
              policyMarine_LCNumber
              policyMarine_LCIssueDate
              policyMarine_Loading
              policyMarine_SumPerShipment
              pricingOption_SumInsured
              pricingOption_NetPremium
              pricingOption_PolicyCostAmount
              pricingOption_ChargesAmount
              pricingOption_TPAFeesTotalAmount
              pricingOption_FixedStampAmount
              pricingOption_ProportionalStampAmount
              pricingOption_MunicipalityTaxAmount
              pricingOption_TotalPremium
              production_Policy_PolicyEffectiveDate
              production_Policy_Status
            }
          }
        }
      }
    }
  `;
}
