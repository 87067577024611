import { gql } from '@apollo/client';

export const getTreatyDetailsQuery = gql`
  query getTreatyDetails($TreatyID: String!) {
    Reinsurance {
      queries {
        GetTreatyDetails(TreatyID: $TreatyID) {
          reinsurance_Treaties_Id
          reinsurance_Treaties_TreatyName
          reinsurance_Treaties_TreatyID
          reinsurance_Treaties_createdBy
          reinsurance_Treaties_createdOn
          reinsurance_Treaties_modifiedBy
          reinsurance_Treaties_modifiedOn
          reinsurance_Treaties_TreatyStatus
          reinsurance_Treaties_TreatyType
          reinsurance_Treaties_EffectiveDate
          reinsurance_Treaties_ExpiryDate
          reinsurance_Treaties_TreatyCurrency {
            Code
            Symbol
          }
          reinsurance_Treaties_TreatyUnderwritingLimit
          reinsurance_Treaties_ClaimAdviseLimit
          reinsurance_Treaties_SubstandardLimit
        }
        GetTreatyReinsurers(TreatyID: $TreatyID) {
          reinsurance_TreatyReinsurers_Id
          treatyReinsurersBusinessPartner_FullName
          reinsurance_TreatyReinsurers_SharePercentage
          reinsurance_TreatyReinsurers_IsLeader
        }
      }
      lookups {
        treatySubType {
          Id
          Code
          Title
          TreatyType
        }
      }
    }
    Reinsurance_TreatyType: __type(name: "Reinsurance_TreatyType") {
      enumValues {
        name
      }
    }
    Reinsurance_SublineCategoryList: __type(
      name: "Reinsurance_SublineCategoryList"
    ) {
      enumValues {
        name
      }
    }
    Core {
      lookups {
        currencies {
          Id
          Code
          Symbol
          Title
        }
      }
    }
  }
`;

export const activateTreatyMutation = gql`
  mutation activeTreaty($CurrentTreatyID: String!) {
    reinsurance {
      actions {
        activateTreaty(CurrentTreatyID: $CurrentTreatyID) {
          id
        }
      }
    }
  }
`;

export const closeTreatyMutation = gql`
  mutation closeTreaty($CurrentTreatyID: String!) {
    reinsurance {
      actions {
        closeTreaty(CurrentTreatyID: $CurrentTreatyID) {
          id
        }
      }
    }
  }
`;
