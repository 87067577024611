import React, { useEffect, useImperativeHandle, useState } from 'react';
import WidgetPaper from '../../../../../../components/common/WidgetPaper';
import WidgetSection from '../../../../../../components/common/WidgetSection';
import { makeStyles } from 'tss-react/mui';
import TextInputFormField from '../../../../../../components/form-fields/TextInputFormField';
import { ITreatyDetails, ITreatyErrors, ITreatyLayer } from '.';
import SelectFormField from '../../../../../../components/form-fields/SelectFormField';
import DatePickerFormField from '../../../../../../components/form-fields/DatePickerFormField';
import CurrencyFormField from '../../../../../../components/form-fields/CurrencyFormField';
import EnhancedPercentageInput from '../../../../../../components/enhanced-form/EnhancedPercentageInput';
import { getMutationVariables, mapToWidgetValues } from './utils';
import { ITreatyDetailsResponse, ITreatyLovs } from '../..';
import EnhancedButton from '../../../../../../components/form-fields/buttons/EnhancedButton';
import { Chip } from '@mui/material';
import { MAIN_ONE_THEME } from '../../../../../../constants';
import { cloneDeep, isEqual } from 'lodash';
import { addDays, isDateBefore } from '../../../../../../utils/date-utils';
import { initialTreatyValues, validationRules } from './content';
import TabsRepeater from './TabsRepeater';
import { useMutation, useQuery } from '@apollo/client';
import { getTreatyLayerAndCategoryQuery, saveTreatyMutation } from './queries';
import Loader from '../../../../../../components/Loader';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../../../../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../../../../../components/ToastErrorMessage';
import { getError } from '../../../../../../utils/graph-utils';

interface ITreatyDetailsWidget {
  currentTreatyId: string;
  data: ITreatyDetailsResponse;
  errors: ITreatyErrors;
  updateErrors: (errors: ITreatyErrors) => void;
  lovs: ITreatyLovs;
  shouldSave: boolean;
  setShouldSave: (shouldSave: boolean) => void;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: '100%',
  },
  fieldRow: {
    display: 'grid',
    gridTemplateColumns: `repeat(3, 32%)`,
    gap: '2%',
  },
  dirtyChip: {
    marginBottom: '-7px',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
}));

const TreatyDetailsWidget = React.forwardRef(
  (props: ITreatyDetailsWidget, ref) => {
    const {
      currentTreatyId,
      data,
      errors,
      updateErrors,
      lovs,
      shouldSave,
      setShouldSave,
    } = props;

    const { classes } = useStyles();
    const [values, setValues] = useState<ITreatyDetails>(
      initialTreatyValues.values
    );

    const [saveTreaty] = useMutation(saveTreatyMutation);
    const { data: layersResponse, loading } = useQuery(
      getTreatyLayerAndCategoryQuery,
      {
        variables: {
          TreatyID: data?.reinsurance_Treaties_Id,
        },
      }
    );

    const isDisabled =
      data?.reinsurance_Treaties_TreatyStatus?.toLowerCase() !== 'new';

    const handleFieldChange = (
      newValue: any,
      fieldName: keyof ITreatyDetails
    ) => {
      const newValues = cloneDeep(values);
      const valueChanged = !isEqual(newValues[fieldName], newValue);
      if (valueChanged) {
        if (fieldName === 'treatyType') {
          const isProportional = newValue.toLowerCase() === 'proportional';

          newValues.substandardLimit = isProportional ? '100' : '';
          newValues.layers.tab_0.treatySubType = isProportional
            ? 'QuotaShare'
            : 'ExcessOfLoss';
        }

        newValues[fieldName] = newValue;
        setValues(newValues);
        setShouldSave(true);
      }
    };

    const handleFieldBlur = (fieldName: string) => {
      if (fieldName === 'effectiveDate' || fieldName === 'expiryDate') {
        validateDates();
      }
    };

    const validateField = (
      value: any,
      fieldName: string,
      errorObj: any
    ): boolean => {
      if (!value) {
        errorObj[fieldName] = 'Required';
        return true;
      } else {
        errorObj[fieldName] = '';
        return false;
      }
    };

    const validateForm = (): boolean => {
      const newErrors = cloneDeep(errors);
      let hasError = false;

      const { treatyType } = values;

      if (!treatyType) {
        newErrors.treatyType = 'Required';
        hasError = true;
      } else {
        newErrors.treatyType = '';

        const rulesForTreatyType =
          validationRules[
            treatyType?.toLowerCase() as keyof typeof validationRules
          ];

        // Validate top-level fields based on the rules
        for (const field in rulesForTreatyType) {
          if (
            field !== 'layer' &&
            rulesForTreatyType[field as keyof typeof rulesForTreatyType]
          ) {
            hasError =
              validateField(
                values[field as keyof ITreatyDetails],
                field,
                newErrors
              ) || hasError;
          }
        }

        // Validate layers
        for (const layerKey in values.layers) {
          const layer = values.layers[layerKey];
          const { treatySubType } = layer;

          hasError =
            validateField(
              treatySubType,
              'treatySubType',
              newErrors.layers[layerKey]
            ) || hasError;

          if (treatySubType) {
            // Get rules for the layer type
            const layerRules = (rulesForTreatyType.layer as any)[treatySubType];

            for (const field in layerRules) {
              if (field !== 'categories' && layerRules[field]) {
                hasError =
                  validateField(
                    layer[field as keyof ITreatyLayer],
                    field,
                    newErrors.layers[layerKey]
                  ) || hasError;
              }
            }

            // Validate categories
            layer.categories.forEach((category: any, index: number) => {
              const categoryRules = layerRules.categories;

              for (const field in categoryRules) {
                if (categoryRules[field]) {
                  hasError =
                    validateField(
                      category[field],
                      field,
                      (newErrors.layers as any)[layerKey].categories[index]
                    ) || hasError;
                }
              }
            });
          }
        }
      }
      updateErrors(newErrors);
      if (hasError) {
        toast.error(<ToastErrorMessage>Incomplete Form</ToastErrorMessage>);
      }
      return !hasError;
    };

    useImperativeHandle(ref, () => ({
      validateForm,
    }));

    const handleSave = async () => {
      const isValid = validateForm();

      if (isValid) {
        try {
          const mutationVariables = getMutationVariables(
            currentTreatyId,
            values
          );

          const res = await saveTreaty({
            variables: mutationVariables,
          });

          if (!res.errors) {
            setShouldSave(false);
            toast.success(
              <ToastSuccessMessage>
                Successfully updated treaty details
              </ToastSuccessMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      }
    };

    const validateDates = () => {
      if (!values.effectiveDate || !values.expiryDate) {
        return;
      }
      const newErrors = cloneDeep(errors);
      const error = isDateBefore(
        new Date(values.expiryDate),
        new Date(values.effectiveDate)
      )
        ? 'Cannot be less than Effective Date'
        : '';

      newErrors.expiryDate = error;
      updateErrors(newErrors);
    };

    const initializeErrors = (values: ITreatyDetails): ITreatyErrors => {
      const errors: ITreatyErrors = {
        treatyName: '',
        treatyType: '',
        effectiveDate: '',
        expiryDate: '',
        currency: '',
        underwritingLimit: '',
        claimAdviseLimit: '',
        substandardLimit: '',
        layers: {},
      };

      for (const layerKey in values.layers) {
        const layer = values.layers[layerKey];
        errors.layers[layerKey] = {
          treatySubType: '',
          quotaShare: '',
          paymentDate: '',
          paymentAmount: '',
          totalTreatyPremium: '',
          totalExpectedPremium: '',
          premiumRate: '',
          categories: [],
        };

        layer.categories.forEach(() => {
          errors.layers[layerKey].categories.push({
            category: '',
            minimumLayerLimit: '',
            maximumLayerLimit: '',
            retentionLimit: '',
            priorityLimit: '',
            liabilityLimit: '',
            aggregateLimit: '',
            commissionRate: '',
          });
        });
      }

      return errors;
    };

    useEffect(() => {
      if (data && layersResponse) {
        const widgetValues = mapToWidgetValues(
          data,
          layersResponse.Reinsurance.queries.GetTreatyLayerAndCategory
        );

        const initialErrors = initializeErrors(widgetValues);
        updateErrors(initialErrors);
        setValues(widgetValues);
      }
    }, [data, layersResponse]);

    return loading ? (
      <Loader />
    ) : (
      <WidgetPaper>
        <WidgetSection
          title="Treaty Details"
          hasTitleSpecificDesign={false}
          useSeparator={false}
          middle={
            shouldSave &&
            !isDisabled && (
              <Chip
                label={'Treaty details were changed, please Save again'}
                className={classes.dirtyChip}
              />
            )
          }
          actions={
            isDisabled ? null : (
              <EnhancedButton onClick={handleSave} disabled={!shouldSave}>
                Save
              </EnhancedButton>
            )
          }
        >
          <div className={classes.fieldRow}>
            <TextInputFormField
              name="treatyName"
              title="Treaty Name*"
              placeholder="Enter Treaty Name"
              value={values.treatyName}
              onChange={(e) => {
                handleFieldChange(e.target.value, 'treatyName');
              }}
              error={errors.treatyName}
              disabled={isDisabled}
            />
            <SelectFormField
              name="treatyType"
              title="Treaty Type*"
              placeholder="Select Treaty Type"
              selectOptions={lovs.treatyType}
              value={values.treatyType}
              onChange={(v) => {
                handleFieldChange(v, 'treatyType');
              }}
              error={errors.treatyType}
              disabled={isDisabled}
            />
            <DatePickerFormField
              name="effectiveDate"
              title="Effective Date*"
              value={values.effectiveDate}
              onDateChange={(v) => {
                handleFieldChange(v.toString(), 'effectiveDate');
              }}
              onBlur={() => {
                handleFieldBlur('effectiveDate');
              }}
              error={errors.effectiveDate}
              disabled={isDisabled}
            />
            <DatePickerFormField
              name="expiryDate"
              title="Expiry Date*"
              value={values.expiryDate}
              minDate={addDays(new Date(values.effectiveDate), 1)}
              onDateChange={(v) => {
                handleFieldChange(v.toString(), 'expiryDate');
              }}
              onBlur={() => {
                handleFieldBlur('expiryDate');
              }}
              error={errors.expiryDate}
              disabled={isDisabled}
            />
            <SelectFormField
              name="currency"
              title="Currency*"
              placeholder="Select Currency"
              selectOptions={lovs.currency}
              value={values.currency}
              onChange={(v) => {
                handleFieldChange(v, 'currency');
              }}
              error={errors.currency}
              disabled={isDisabled}
            />
            <CurrencyFormField
              name="underwritingLimit"
              title="Underwriting Limit*"
              placeholder="Enter Underwriting Limit"
              value={values.underwritingLimit}
              onChange={(e) => {
                handleFieldChange(e.target.value, 'underwritingLimit');
              }}
              currencySymbol={lovs?.currency?.[values.currency]}
              error={errors.underwritingLimit}
              disabled={isDisabled}
            />
            <CurrencyFormField
              name="claimAdviseLimit"
              title="Claim Advise Limit*"
              placeholder="Enter Claim Advise Limit"
              value={values.claimAdviseLimit}
              onChange={(e) => {
                handleFieldChange(e.target.value, 'claimAdviseLimit');
              }}
              currencySymbol={lovs?.currency?.[values.currency]}
              error={errors.claimAdviseLimit}
              disabled={isDisabled}
            />
            {values?.treatyType?.toLowerCase() === 'proportional' && (
              <EnhancedPercentageInput
                name="substandardLimit"
                title="Substandard Limit*"
                placeholder="Enter Substandard Limit"
                value={values.substandardLimit}
                onChange={(e) => {
                  handleFieldChange(e.target.value, 'substandardLimit');
                }}
                onBlur={() => {}}
                error={errors.substandardLimit}
                disabled={isDisabled}
              />
            )}
          </div>
        </WidgetSection>
        <WidgetSection>
          <TabsRepeater
            values={values}
            errors={errors}
            onChange={(allValues: ITreatyDetails) => {
              setValues(allValues);
              setShouldSave(true);
            }}
            updateErrors={(allErrors: ITreatyErrors) => {
              updateErrors(allErrors);
            }}
            lovs={lovs}
            showTabs={values.treatyType === 'PROPORTIONAL'}
            tabsTitle="Layer"
            isDisabled={isDisabled}
          />
        </WidgetSection>
      </WidgetPaper>
    );
  }
);

TreatyDetailsWidget.displayName = 'TreatyDetailsWidget';

export default TreatyDetailsWidget;
