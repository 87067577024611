import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from '../../../../../components/enhanced-table';

export const headers: Record<string, EnhancedTableHeader> = {
  accounting_Bills_BillNumber: {
    name: 'accounting_Bills_BillNumber',
    title: 'Bill #',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Bills_DueDate: {
    name: 'accounting_Bills_DueDate',
    title: 'Due Date',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Bills_TotalPremium: {
    name: 'accounting_Bills_TotalPremium',
    title: 'Total Premium',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  receivablePayment_AmountReceived: {
    name: 'receivablePayment_AmountReceived',
    title: 'Amount Received',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  accounting_Bills_TotalCommission: {
    name: 'accounting_Bills_TotalCommission',
    title: 'Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
    tooltipSettings: {
      headers: {
        BusinessPartner: {
          name: 'BusinessPartner',
          title: 'Business Partner',
          type: EnhancedTableHeaderType.Text,
          tooltipSettings: null,
        },
        Share: {
          name: 'Share',
          title: 'Share',
          type: EnhancedTableHeaderType.Percentage,
          tooltipSettings: null,
        },
        Commission: {
          name: 'Commission',
          title: 'Commission',
          type: EnhancedTableHeaderType.RoundedCurrency,
          tooltipSettings: null,
        },
      },
    },
  },
  payableBills_TaxOnCommission: {
    name: 'payableBills_TaxOnCommission',
    title: 'Tax on Commission',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  payablePayment_AmountReceived: {
    name: 'payablePayment_AmountReceived',
    title: 'Amount Paid',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
  totalBalance: {
    name: 'totalBalance',
    title: 'Total Balance',
    type: EnhancedTableHeaderType.RoundedCurrency,
  },
};
